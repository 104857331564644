<template>
  <!-- 人员管理 --- 人员审核 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="组织：">
        <el-cascader popper-class="my-cascader" filterable v-model="queryForm.organize_id" clearable checkStrictly
          :props="defaultProps" placeholder="请选择组织..." :options="organizationSeleteList" @change="onSubmit()">
        </el-cascader>
        <!-- <el-select popper-class="my-select" filterable v-model="queryForm.organize_id" placeholder="请选择组织" clearable @change="onSubmit">
          <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name" :value="item._id"></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="姓名：">
        <el-input maxlength="15" v-model="queryForm.person_name" @keyup.enter.native="onSubmit()" placeholder="请输入姓名"
          @input="(e) => (queryForm.person_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input maxlength="11" v-model="queryForm.phone" @keyup.enter.native="onSubmit()" placeholder="请输入手机号"
          @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="来源：">
        <el-select popper-class="my-select" v-model="queryForm.person_source" placeholder="请选择来源" style="width:100%" @change="onSubmit()" clearable filterable  @clear="queryForm.person_source = null">
          <el-option v-for="item in getOptions('RegisterPersonSource')" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select popper-class="my-select" v-model="queryForm.audit_status" placeholder="请选择审核状态" style="width:100%" @change="onSubmit()" clearable filterable  @clear="queryForm.audit_status = null">
          <el-option v-for="item in auditStatusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="人员审核">
      <!-- 主要内容 -->
      <div>
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="register_pic">
              <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]">
              </el-image>
            </template>
            <template slot-scope="{row}" slot="audit_status">
              <span>{{ getWayName("PersonAuditStatus",row.audit_status) }} </span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="setting" icon="bianji" hint="审核" @click="fnAuditHandle(row)"
                v-if="row.audit_status == 1"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetails(row)" v-else></lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
          border stripe @selection-change="handleSelectionChange">
          <!-- <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column> -->
          <el-table-column label="图像" width="100">
            <template slot-scope="scope">
              <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="person_name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="selected_organize_name" label="所属组织" show-overflow-tooltip></el-table-column>
          <el-table-column prop="audit_status" label="状态">
            <template slot-scope="scope">
              <span>{{ getWayName("PersonAuditStatus", scope.row.audit_status) }} </span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="来源">
            <template slot-scope="scope">
              <span>{{ getWayName("RegisterPersonSource", scope.row.person_source) }} </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="setting" icon="shenhe" hint="审核" @click="fnAuditHandle(scope.row)"
                v-if="scope.row.audit_status == 1"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetails(scope.row)" v-else></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 审核对话框 -->
    <lebo-dialog append-to-body title="审核" :isShow="isAuditDialog" width="45%" @close="isAuditDialog = false" footerSlot
      closeOnClickModal>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-form :model="auditForm" :rules="auditFormRules" ref="auditFormRef" label-width="120px">
            <el-form-item label="姓名：">{{ auditForm.person_name }}</el-form-item>
            <el-form-item label="性别："> {{ auditForm.sex === 1 ? '女' : '男' }}</el-form-item>
            <el-form-item label="手机号：">{{ auditForm.phone }}</el-form-item>
            <el-form-item label="身份证：">{{ auditForm.card_num }}</el-form-item>
            <el-form-item label="所属组织：">{{ auditForm.selected_organize_name }}</el-form-item>
            <template v-if="auditForm.audit_status == 1">
              <el-form-item label="人员类型：" prop="person_type">
                <el-select popper-class="my-select" filterable v-model="auditForm.person_type" placeholder="请选择类型">
                  <el-option v-for="item in getOptions('RegisterPersonType')" :key="item.value" :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="通行权限组：">
                <el-select popper-class="my-select" ref="permissionSelect" filterable v-model="auditForm.permission_id"
                  placeholder="请选择通行权限组" clearable @change="permissionSelectChange">
                  <el-option v-for="item in permissionGroupSelectList" :key="item._id" :label="item.permission_group_name"
                    :value="item._id"></el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-else-if="auditForm.audit_status === 2">
              <el-form-item label="拒绝原因：">{{ auditForm.refusal_cause }}</el-form-item>
            </template>
            <template v-else>
              <el-form-item label="人员类型：">{{ getWayName('RegisterPersonType', auditForm.person_type) }}</el-form-item>
              <el-form-item label="通行权限组：">{{ auditForm.permission_name || '无' }}</el-form-item>
            </template>
          </el-form>
        </el-col>
        <el-col :span="8">
          <el-image fit="cover" style="width:100%; height: 300px" :src="auditForm.register_pic"
            :preview-src-list="[auditForm.register_pic]" :z-index="9999">
          </el-image>
          <div class="maskBox">人员图像</div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer" v-if="auditForm.audit_status == 1">
        <lbButton type="warning" fill icon="back" @click="isCauseDialog = true">拒 绝</lbButton>
        <lbButton type="orign" icon="confirm" @click="submitAddForm(true)" v-preventReClick>同 意</lbButton>
      </span>
      <lebo-dialog append-to-body title="拒绝原因" :isShow="isCauseDialog" width="30%" @close="isCauseDialog = false"
        footerSlot>
        <el-form :model="auditForm" :rules="auditFormRules" ref="auditFormRef" label-width="0">
          <el-form-item prop="refusal_cause">
            <el-input type="textarea" maxlength="300" v-model.trim="auditForm.refusal_cause" :rows="5"
              placeholder="请输入拒绝原因"
              @blur="auditForm.refusal_cause = ((auditForm.refusal_cause.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <!-- <lbButton type="goBack" icon="back" class="checkButton3" @click="isCauseDialog = false">返 回</lbButton> -->
          <lbButton type="default" fill icon="confirm" @click="submitAddForm(false)" v-preventReClick>确 定</lbButton>
        </span>
      </lebo-dialog>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getPersonnelReviewsList, getPermissionGroupSelectList, auditPersonnelReview, getOrganizatioTreeList } from '@/api/administrative'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        organize_id: '',
        person_name: '',
        phone: '',
        card_num: '',
        person_source: null, // 来源RegisterPersonSource
        audit_status: null// PersonAuditStatus审核状态
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 组织下拉列表
      organizationSeleteList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        // multiple: true,可以选择多个选项
        checkStrictly: true // 可以选择父类名称进行选择
      },
      isAuditDialog: false, // 是否展示审核对话框
      isCauseDialog: false,
      auditForm: {
        person_type: 1,
        permission_id: '',
        refusal_cause: ''
      },
      auditFormRules: {
        person_type: [{ required: true, message: '请选择人员类型', trigger: 'blur' }],
        refusal_cause: [{ required: true, message: '请输入拒绝原因', trigger: 'blur' }]
      },
      permissionGroupSelectList: [], // 通行权限组下拉列表
      auditStatusList: [],
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '图像',
            prop: 'register_pic',
            slot: true
          }, {
            label: '姓名',
            prop: 'person_name'
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '所属组织',
            prop: 'selected_organize_name',
            width: 350,
            slot: true
          }, {
            label: '状态',
            prop: 'audit_status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetOrganizationSeleteList()
    this.fnGetPersonnelReviewsList()
  },
  mounted () {
    // 筛选枚举列表
    const filteStatusList = this.getOptions('PersonAuditStatus')
    this.auditStatusList = filteStatusList.filter(item => {
      return item.value !== 4 && item.value !== 5
    })
  },
  computed: {
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizatioTreeList()
      this.organizationSeleteList = res && res.Code === 200 ? this.filterChildren(res.Data.concat()) : []
    },
    // 获取组织成员数据列表
    async fnGetPersonnelReviewsList () {
      const res = await getPersonnelReviewsList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        person_name: this.queryForm.person_name,
        phone: this.queryForm.phone,
        organize_id: this.queryForm.organize_id && this.queryForm.organize_id.length > 0 ? this.queryForm.organize_id[this.queryForm.organize_id.length - 1] : null,
        person_source: this.queryForm.person_source ? this.queryForm.person_source : 0,
        audit_status: this.queryForm.audit_status ? this.queryForm.audit_status : 0
      })
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetPersonnelReviewsList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetPersonnelReviewsList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPersonnelReviewsList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetPersonnelReviewsList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPersonnelReviewsList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 审核
    fnAuditHandle (item) {
      console.log('item----', item)
      this.auditForm = item
      this.auditForm.person_type = this.auditForm.person_type || 1
      this.auditForm.permission_id = this.auditForm.permission_id !== '000000000000000000000000' ? this.auditForm.permission_id : ''
      this.isAuditDialog = true
      this.fnGetPermissionGroupSelectList()
    },
    // 根据组织id获取通行权限组列表
    async fnGetPermissionGroupSelectList () {
      const res = await getPermissionGroupSelectList({ organ_id: this.auditForm.organize_id })
      console.log(res)
      this.permissionGroupSelectList = res && res.Code === 200 ? res.Data : []
    },
    // 查看审核详情
    lookDetails (item) {
      console.log('item----', item)
      this.auditForm = item
      this.isAuditDialog = true
    },
    permissionSelectChange (v) {
      this.$nextTick(() => {
        this.auditForm.permission_name = this.$refs.permissionSelect.selectedLabel
      })
    },
    // 提交审核(同意)
    submitAddForm (flag) {
      this.$refs.auditFormRef.validate((valid) => {
        if (valid) {
          this.fnauditPersonnelReview(flag)
        } else {
          return false
        }
      })
    },
    // 请求接口
    async fnauditPersonnelReview (flag) {
      var obj = {
        review_id: this.auditForm._id,
        isagree: flag,
        person_type: this.auditForm.person_type // 人员类型 1.普通人员 2.巡检人员 3.维修人员
        // refusal_cause: "" // 拒绝原因（同意可不传）
      }
      if (this.auditForm.permission_id) {
        obj.permission_id = this.auditForm.permission_id
        obj.permission_name = this.auditForm.permission_name
      }
      if (!flag) {
        obj.refusal_cause = this.auditForm.refusal_cause
      }
      const res = await auditPersonnelReview(obj)
      console.log('res----', res)
      if (res && res.Code === 200) {
        this.isAuditDialog = false
        this.isCauseDialog = false
        this.fnGetPersonnelReviewsList()
      }
    }

  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.el-image {
  width: 55px;
  height: 55px;

  /deep/.el-image__error {
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size: 0;
  }
}

.maskBox {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, .5);
  margin-top: -7px;
}
</style>
